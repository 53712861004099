import UserAdelantoSaldo from 'components/paquetes/UserAdelantoSaldo';
import IUsuario from 'interfaces/IUsuario';
import { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';

type ResUser = {
    code: number;
    error: boolean;
    message: string;
    usuario: IUsuario;
}

type ResExt = {
    error: boolean;
    code: number;
    montoPermitido: string;
    retiroPermitido: boolean;
    calificacionVencida: boolean
}

export default function RetiroExteporaneo() {

    const [userInfo, setUserInfo] = useState<IUsuario | null>(null);
    const [saldoDisponible, setSaldoDisponible] = useState<number>();
    const [message, setMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [monto, setMonto] = useState<number>();
    // const [montoPer, setMontoPer] = useState<string | null>(null);
    const [retiroPer, setRetiroPer] = useState<boolean>();
    const [calificacionVencida, setCalificacionVencida] = useState<boolean>();

    const fetchUserInfo = () => {
        AppleSystemConsume('usuario')
            .get('my-usuario')
            .then((res: ResUser) => {
                setUserInfo(res.usuario);
            });
    }

    const getSaldo = () => {
        AppleSystemConsume('usuario')
            .get('available-balance')
            .then((res) => {
                if (res.error) throw new Error;
                setSaldoDisponible(res.avaliableBalance)
            })
    }

    const getRetiroE = () => {
        AppleSystemConsume('withdrawal')
            .get('solicitar-retiro-extemporaneo')
            .then((res: ResExt) => {
                // setMontoPer(res.montoPermitido)
                setRetiroPer(res.retiroPermitido)
                setCalificacionVencida(res.calificacionVencida)
            })
    }

    useEffect(() => {
        fetchUserInfo()
        getSaldo();
        getRetiroE()
    }, []);

    const postRetiro = () => {
        setIsLoading(true)
        AppleSystemConsume('withdrawal')
            .post('solicitar-retiro-extemporaneo', { monto }, {})
            .then((res) => {
                setIsLoading(false)
                if (res.error) {
                    setError(true)
                    setMessage(res.message)
                } else {
                    setError(false)
                    setMessage(res.message)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                setError(true)
                setMessage(err.message)
            })
    }

    return (
        <div className='container py-5'>
            <div className='row justify-content-center'>
                <div className='col-md-8'>
                    <div className='card shadow-sm'>
                        <div className='card-body'>
                            {userInfo && saldoDisponible !== undefined && (
                                <UserAdelantoSaldo
                                    isMant={false}
                                    usuario={userInfo}
                                    saldoDisponible={saldoDisponible}
                                    titleSaldo='Saldo disponible:'
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center my-4'>
                <div className='col-md-6'>
                    <div className='text-center'>
                        <h5 className='font-weight-bold'>Solicitar retiro Extemporaneo</h5>
                    </div>
                    <div className='d-flex flex-column m-auto gap-5 justify-content-center items-center mb-3'>
                        <div className='form-group'>
                            <label className='form-label'>Monto</label>
                            <input
                                onChange={e => setMonto(Number(e.target.value))}
                                name='retiroExteporaneo'
                                value={monto}
                                type='number'
                                className='form-control text-center w-100' />
                        </div>
                        <div className="alert alert-danger p-3 text-center">
                            <p>
                                El máximo valor a retirar por operación es de 1000 SApoint y un retiro por corte.
                                Invitamos a usar transferencia de saldo, comprar y pagar sus servicios en Apple Golden y Treidblue.
                                El retiro extemporáneo tiene un costo del 10% del total a retirar y una demora de 3 días hábiles.
                                El máximo a retirar será de {saldoDisponible && saldoDisponible * 0.9}.
                            </p>
                        </div>
                    </div>
                    {calificacionVencida === true ?
                        <p className='alert alert-warning text-center'>
                            Debe pagar la calificacion para realizar un retiro extemporaneo
                        </p> :
                        <button disabled={!retiroPer} className='btn btn-primary w-100' onClick={postRetiro}>Solicitar retiro exteporaneo</button>}
                    {!retiroPer ? <p className='text-center text-danger'>Tiene un retiro pendiente, no puede realizar otro.</p> : <></>}
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-4 text-center'>
                    {saldoDisponible && saldoDisponible < 300 ? <p className='text-danger'>No tiene fondos suficientes</p> : <></>}
                    {isLoading ? <p className='text-center fs-6 mt-3'>Cargando...</p> :
                        <p className={`text-center mt-3 fs-6 ${error ? 'text-danger' : 'text-success'}`}>{message && message.toUpperCase()}</p>}
                </div>
            </div>
        </div>
    )
}