import { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';

export default function CompraPuntosAG() {

    const [saldoDisponible, setSaldoDisponible] = useState<number>();
    const [monto, setMonto] = useState<number>();
    const [message, setMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const getSaldo = () => {
        AppleSystemConsume('usuario')
            .get('available-balance')
            .then((res) => {
                if (res.error) throw new Error;
                setSaldoDisponible(res.avaliableBalance)
            })
    }

    useEffect(() => {
        getSaldo();
    }, []);

    const postPointsAG = () => {
        setIsLoading(true)
        AppleSystemConsume('usuario')
            .post('comprar-puntos-ag', {}, {})
            .then((res) => {
                setIsLoading(false)
                if (res.error) {
                    setError(true);
                    setMessage(res.message);
                } else {
                    setError(false);
                    setMessage(res.message);
                }
            })
            .catch((err) => {
                setError(true);
                setMessage(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <div className='container py-5'>
            <div className='row justify-content-center'>
                <div className='col-md-8'>
                    <div className='card shadow-sm p-3 text-center'>
                        <h1 className='text-center font-bold mb-5'>Comprar Puntos Apple Golden</h1>
                        <span className={`text-start ${saldoDisponible &&
                             saldoDisponible < 0 ? 'text-danger' : 'text-success'}`}>Saldo Disponible: ${saldoDisponible}</span>
                        <div className='card-body'>
                            <label htmlFor="">Costo en SApoints:</label>
                            <input onChange={e => setMonto(Number(e.target.value))}
                                value={monto} type="number" placeholder='monto' className='m-auto form-control text-center w-25' />
                        </div>
                        <div className='card-body'>
                            <label htmlFor="">Puntos Apple Golden a obtener:</label>
                            <input value={monto && monto * 1.25} type="number" readOnly className='m-auto form-control text-center w-25' />
                        </div>
                        <button className='m-auto btn btn-success w-25' onClick={postPointsAG}>Confirmar Compra</button>
                        {isLoading ? <p className='text-center fs-6 mt-3'>Cargando...</p> :
                            <p className={`text-center mt-3 fs-6 ${error ? 'text-danger' : 'text-success'}`}>{message && message.toUpperCase()}</p>}
                    </div>
                </div>
            </div>

        </div>
    )
} 