import UserAdelantoSaldo from 'components/paquetes/UserAdelantoSaldo';
import IUsuario from 'interfaces/IUsuario';
import { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';

type ResUser = {
    code: number;
    error: boolean;
    message: string;
    usuario: IUsuario;
}

export default function AdquirirMantenimiento() {

    const [userInfo, setUserInfo] = useState<IUsuario | null>(null);
    const [saldoDisponible, setSaldoDisponible] = useState<number>();
    const [financiamiento, setFinanciamiento] = useState<number>();
    const [message, setMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const fetchUserInfo = () => {
        AppleSystemConsume('usuario')
            .get('my-usuario')
            .then((res: ResUser) => {
                setUserInfo(res.usuario);
            });
    }

    const getSaldo = () => {
        AppleSystemConsume('usuario')
            .get('available-balance')
            .then((res) => {
                if (res.error) throw new Error;
                setSaldoDisponible(res.avaliableBalance)
                setFinanciamiento(res.maxFinancing)
            })
    }

    useEffect(() => {
        fetchUserInfo()
        getSaldo();
    }, []);

    const postMantenimiento = () => {
        setIsLoading(true)
        AppleSystemConsume('usuario')
            .post('adquirir-mantenimiento', {}, {})
            .then((res) => {
                setIsLoading(false)
                if (res.error) {
                    setError(true); 
                    setMessage(res.message);
                } else {
                    setError(false); 
                    setMessage(res.message);
                }
            })
            .catch((err) => {
                setError(true); 
                setMessage(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };    

    return (
        <div className='container py-5'>
            <div className='row justify-content-center'>
                <div className='col-md-8'>
                    <div className='card shadow-sm'>
                        <div className='card-body'>
                            {userInfo && saldoDisponible !== undefined && (
                                <UserAdelantoSaldo
                                    isMant={true}
                                    isExt={false}
                                    usuario={userInfo}
                                    saldoDisponible={saldoDisponible}
                                    titleSaldo='Saldo disponible:'
                                    financiamientoPermitido={financiamiento}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center my-4'>
                <div className='col-md-6'>
                    <div className='text-center'>
                        <h5 className='font-weight-bold'>Adquirir mantenimiento</h5>
                    </div>
                    <div className='d-flex m-auto gap-5 justify-content-center items-center mb-3'>
                        <div className='form-group'>
                            <label className='form-label'>Costo de mantenimiento</label>
                            <input
                                name='mantenimiento'
                                value={300}
                                readOnly
                                type='number'
                                className='form-control text-center' />
                        </div>
                        <div className="alert alert-danger p-3 text-center">
                            <p>Al adquirir el mantenimiento se descontarán 300 Sacoins de su saldo.</p>
                        </div>
                    </div>
                    <p className='alert alert-warning text-center'>Estimado Representante usted está comprando el mantenimiento
                        de RA para el mes actual, este valor será compensado en puntos Apple Golden.
                        Esta compra de mantenimiento no tiene devolución.</p>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-4 text-center'>
                    <button disabled={isLoading} className='btn btn-primary w-100' onClick={postMantenimiento}>Confirmar</button>
                    {saldoDisponible && saldoDisponible < 300
                        || financiamiento && financiamiento < 300 ?
                        <p className='text-danger'>No tiene fondos suficientes</p> : <></>}
                    {isLoading ? <p className='text-center fs-6 mt-3'>Cargando...</p> :
                        <p className={`text-center mt-3 fs-6 ${error ? 'text-danger' : 'text-success'}`}>{message && message.toUpperCase()}</p>}
                </div>
            </div>
        </div>
    )
}

