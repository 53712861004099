import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from 'configs';

import PanelObservacion from 'pages/admin/PanelObservacion';
import EditProfile from 'pages/user/EditProfile'
import Login from 'pages/auth/Login.page';
import { DatosCuenta } from 'pages/auth/register/components/DatosCuenta';
import { DatosCuentaSponsor } from 'pages/auth/register/components/DatosCuentaSponsor';
import { ValidarCorreo } from 'pages/auth/register/components/ValidarCorreo';
import { ValidarCelular } from 'pages/auth/register/components/ValidarCelular';
import { RegistrationProvider } from 'pages/auth/register/context/RegistrationContext';
import { RegistrationDone } from 'pages/auth/register/components/RegistrationDone';
import OldPortal from 'layouts/OldPortal.layout';
import { PagosServicios } from 'pages/user/PagosServicios';
import { EstadoCuenta } from 'pages/user/EstadoCuenta';
import ComprasServicios from 'pages/user/ComprasServicios';
import RepresentantesRed from 'pages/user/RepresentantesRed';
import Comisiones from 'pages/user/Comisiones';
import Faq from 'pages/user/Faq';
import News from 'pages/user/News';
import MyQualifications from 'pages/user/MyQualifications';
import MyContacts from 'pages/user/MyContacts';
import RecoveryPassword from 'pages/auth/login/components/recovery/RecoveryPassword';
import RecoveredPassword from 'pages/auth/login/components/recovery/RecoveredPassword';
import Dashboard from 'pages/user/Dashboard';
import Retiros from 'pages/user/Retiros';
import Saldos from 'pages/user/Saldos';
import Paquetes from 'pages/user/Paquetes';
import Transferencias from 'pages/user/Transferencias';
import ReloadRecovey from 'pages/auth/login/components/recovery/ReloadRecovey';
import FormRecoveryPassword from 'pages/user/FormRecoveryPassword';
import ConcursosPremios from 'pages/user/ConcursosPremios';
import Viajes from 'pages/user/Viajes';
import Recomiendate from 'pages/user/Recomiendate';
import Activities from 'pages/user/Activities';
import PaqueteFondeo from 'pages/user/PaqueteFondeo';
import PaqueteAdquirido from 'pages/user/PaqueteAdquirido';
import SolicitarTransferencia from 'pages/user/SolicitarTransferencia';
import TransferenciaPendiente from 'pages/user/TransferenciaPendiente';
import SolicitarPaquete from 'pages/user/SolicitarPaquete';
import SolicitarRetiro from 'pages/user/SolicitarRetiro';
import AvisoRetiroAceptado from 'pages/user/AvisoRetiroAceptado';
import TablaResponsabilidad from 'pages/user/TablaResponsabilidad';
import DepositoOxxo from 'pages/user/DepositoOxxo';
import DepositoBancario from 'pages/user/DepositoBancario';
import AdquirirSaldo from 'pages/user/AdquirirSaldo';
import ConfirmarFondeoGlobal from 'pages/user/ConfirmarFondeoGlobal';
import ConfirmarFondeoUsdt from 'pages/user/ConfirmarFondeoUsdt';
import AdelantoDeSaldo from 'pages/user/AdelantoDeSaldo';
import AdquirirMantenimiento from 'pages/user/AdquirirMantenimiento';
import RetiroExteporaneo from 'pages/user/RetiroExteporaneo';
import CompraPuntosAG from 'pages/user/CompraPuntosAG';
import { Registration } from '../pages/auth/register/components/Registration';
import { CorreoElectronico } from '../pages/auth/register/components/CorreoElectronico';
import { Celular } from '../pages/auth/register/components/Celular';
import { Password } from '../pages/auth/register/components/Password';
import { DatosPersonales } from '../pages/auth/register/components/DatosPersonales';
import UserLayout from '../layouts/UserLayout.layout';


// agencia de viajes
const AgenciaDeViajeAbiertas = lazy(() =>
  import('pages/admin/agencia-de-viajes/AgenciaDeViajeAbiertas').then((module) => ({ default: module.AgenciaDeViajeAbiertas }))
);
const AgenciaDeViajeCerradas = lazy(() =>
  import('pages/admin/agencia-de-viajes/AgenciaDeViajeCerradas').then((module) => ({ default: module.AgenciaDeViajeCerradas }))
);
const AgenciaDeViajeConfirmadas = lazy(() =>
  import('pages/admin/agencia-de-viajes/AgenciaDeViajeConfirmadas').then((module) => ({ default: module.AgenciaDeViajeConfirmadas }))
);

// Layouts
const AdminLayout = lazy(() => import('layouts/AdminLayout.layout').then((module) => ({ default: module.AdminLayout })));

// Pages

const LoginV2 = lazy(() => import('pages/auth/login/Login.page'));

// Pago Sistema Apple
const ListarPagoAS = lazy(() => import('pages/admin/pago-sistema-apple/ListarPagoAS.page'));

// concursos y pagos
const ConcursoViajes = lazy(() => import('pages/admin/concursos-y-premios/ConcursoViajes.page'));
const PagarViaje = lazy(() => import('pages/admin/concursos-y-premios/PagarViaje.page'));

// boletos vendidos
const ListarBoletosVendidos = lazy(() => import('pages/admin/boletos-vendidos/ListarBoletosVendidos.page'));

// Soporte
const ListarSoporte = lazy(() => import('pages/admin/soporte/ListarSoporte.page'));

// Puntos generados
const ListarPuntosAppleGolden = lazy(() => import('pages/admin/puntos-apple-golden/ListarPuntosAppleGolden.page'));

// Tarjetas prepagas
const TarjetasPrepagasDisponibles = lazy(() => import('pages/admin/tarjetas-prepagas/TarjetasPrepagasDisponibles.page'));
const TarjetasPrepagasConfirmadas = lazy(() => import('pages/admin/tarjetas-prepagas/TarjetasPrepagasAsignadas.page'));
const TarjetasPrepagasNoConfirmadas = lazy(() => import('pages/admin/tarjetas-prepagas/TarjetasPrepagasNoConfirmadas.page'));

// Porcentaje Diario
const PorcentajeDiario = lazy(() => import('pages/admin/porcentaje-diario/PorcentajeDiario.page'));

// Banco Aenp
const FondearBancoAenp = lazy(() => import('pages/admin/banco-aenp/FondearBancoAenp.page'));
const RetirarBancoAenp = lazy(() => import('pages/admin/banco-aenp/RetirarBancoAenp.page'));
const VerBalanceBancoAenp = lazy(() => import('pages/admin/banco-aenp/VerBalanceBancoAenp.page'));

// ganancias-calificaciones-inscripciones
const GananciasCalifInsc = lazy(() => import('pages/admin/ganancias-calificaciones-inscripciones/GananciasCalifInsc.page'));

// rendimientos
const RendimientosPagados = lazy(() => import('pages/admin/rendimientos/RendimientosPagados.page'));

// Paquetes
const PaquetesActivos = lazy(() => import('pages/admin/paquetes/PaquetesActivos.page'));
const PaquetesCancelados = lazy(() => import('pages/admin/paquetes/PaquetesCancelados.page'));
const PaquetesUsuario = lazy(() => import('pages/admin/paquetes/PaquetesUsuario.page'));
const AgregarPaqueteUsuario = lazy(() => import('pages/admin/paquetes/AgregarPaqueteUsuario.page'));

// Usuarios
const UsuariosRegistrados = lazy(() => import('pages/admin/usuarios/UsuariosRegistrados.page'));
const InscriptosConcurso = lazy(() => import('pages/admin/usuarios/InscriptosConcurso.page'));
const UsuariosPendientesActivacion = lazy(() => import('pages/admin/usuarios/UsuariosPendientesActivacion.page'));
const UsuariosRepresentantesActivos = lazy(() => import('pages/admin/usuarios/UsuariosRepresentantesActivos.page'));
const UsuariosSolicitudValidacion = lazy(() => import('pages/admin/usuarios/UsuariosSolicitudValidacion.page'));
const RepresentantesEnRed = lazy(() => import('pages/admin/usuarios/RepresentantesEnRed.page'));
const AgregarProceso = lazy(() => import('pages/admin/procesos/AgregarProceso'));
const UsariosRepresentantes = lazy(() => import('pages/admin/usuarios/UsariosRepresentantes.page'));

// Solicitudes
const SolicitudesFondeo = lazy(() => import('pages/admin/solicitudes/SolicitudesFondeo.page'));
const SolicitudesRetiro = lazy(() => import('pages/admin/solicitudes/SolicitudesRetiro.page'));
const SolicitudesPaquete = lazy(() => import('pages/admin/solicitudes/SolicitudesPaquete.page'));
const SolicitudesRetioExtemporaneo = lazy(() => import('pages/admin/solicitudes/SolicitudesRetioExtemporaneo.page'));
const SolicitudesTransferencias = lazy(() => import('pages/admin/solicitudes/SolicitudesTrasnferencias.page'));
const SolicitudesAdelantoSaldo = lazy(() => import('pages/admin/solicitudes/SolicitudesAdelantoSaldo.page'));

const Usuario = lazy(() => import('pages/admin/usuarios/Usuario.page'));
const UsuariosDeshabilitados = lazy(() => import('pages/admin/usuarios/UsuariosDeshabilitados.page'));

const MetricsRegister = lazy(() => import('pages/admin/metricas/MetricsRegister.page'));

// STP
const GenerarClabe = lazy(() => import('pages/user/stp/GenearClabe'));
const VerMiSaldo = lazy(() => import('pages/user/stp/VerTransferencias'));
const VerMovimientos = lazy(() => import('pages/user/stp/VerMovimientos'));

const Ordenes = lazy(() => import('pages/admin/stp/ordenes'));

const RequireAdmin = lazy(() => import('layouts/RequireAdmin.layout'));

// graficos
const UserChart = lazy(() => import('pages/admin/charts/UserChart'));

const Home = lazy(() => import('pages/Home.page'));
const AdminHome = lazy(() => import('pages/admin/AdminHome.page'));

const LogHttp = lazy(() => import('pages/admin/logs/LogHttp'));

export default function RoutesFunction() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginV2 />}>
        <Route index element={<LoginV2 />} />
      </Route>
      <Route path="login/password-recovery" element={<RecoveryPassword />} />
      <Route path="mis-datos/confirmar-cambios-bancarios" element={<FormRecoveryPassword />} />
      <Route path="reload-recovery" element={<ReloadRecovey />} />
      <Route path="form-recovery-password" element={<RecoveredPassword />} />
      <Route path="registration" element={<RegistrationProvider><Registration /></RegistrationProvider>}>
        <Route index element={<RegistrationProvider><Registration /></RegistrationProvider>} />
        <Route path="start" element={<RegistrationProvider><Registration /></RegistrationProvider>} />
      </Route>
      <Route path="registration/user_validation" element={<RegistrationProvider><DatosCuenta /></RegistrationProvider>} />
      <Route path="registration/:sponsor" element={<RegistrationProvider><DatosCuentaSponsor /></RegistrationProvider>} />
      <Route path="registration/email_validation" element={<RegistrationProvider><CorreoElectronico /></RegistrationProvider>} />
      <Route path="registration/email_validation_code" element={<RegistrationProvider><ValidarCorreo /></RegistrationProvider>} />
      <Route path="registration/phone_validation" element={<RegistrationProvider><Celular /></RegistrationProvider>} />
      <Route path="registration/phone_validation_code" element={<RegistrationProvider><ValidarCelular /></RegistrationProvider>} />
      <Route path="registration/data_validation" element={<RegistrationProvider><DatosPersonales /></RegistrationProvider>} />
      <Route path="registration/password_validation" element={<RegistrationProvider><Password /></RegistrationProvider>} />
      <Route path="registration/congrats" element={<RegistrationDone />} />
      <Route path="/login-V1" element={<Login />} />
      <Route path='verificar-retiro' element={<AvisoRetiroAceptado />} />
      <Route path="/user-panel" element={<UserLayout />}>
        <Route index element={<Dashboard />} />
        <Route path='pagos-y-servicios' element={<PagosServicios />} />
        <Route path='stp/generar-clabe' element={<GenerarClabe />} />
        <Route path='stp/ver-movimientos' element={<VerMovimientos />} />
        <Route path='mis-transferencias' element={<VerMiSaldo />} />
        <Route path='estadocuenta' element={<EstadoCuenta />} />
        <Route path='compras-servicios' element={<ComprasServicios />} />
        <Route path='representantes-en-red' element={<RepresentantesRed />} />
        <Route path='tus-comisiones' element={<Comisiones />} />
        <Route path='preguntas-frecuentes' element={<Faq />} />
        <Route path='noticias' element={<News />} />
        <Route path='tus-calificaciones' element={<MyQualifications />} />
        <Route path='lista-contactos' element={<MyContacts />} />
        <Route path='reportes/retiros' element={<Retiros />} />
        <Route path='reportes/saldos' element={<Saldos />} />
        <Route path='reportes/paquetes' element={<Paquetes />} />
        <Route path='reportes/transferencias' element={<Transferencias />} />
        <Route path='concursos-y-premios' element={<ConcursosPremios />} />
        <Route path='concursos-y-premios-viajes' element={<Viajes />} />
        <Route path='recomiendate' element={<Recomiendate />} />
        <Route path='cronograma-de-actividades' element={<Activities />} />
        <Route path='solicitar-paquete-fondeo' element={<PaqueteFondeo />} />
        <Route path='paquete-fondeo-adquirido' element={<PaqueteAdquirido />} />
        <Route path='solicitar-transferencia' element={<SolicitarTransferencia />} />
        <Route path='transferencia-pendiente' element={<TransferenciaPendiente />} />
        <Route path='confirmar-fondeo-deposito-oxxo' element={<DepositoOxxo />} />
        <Route path='solicitar-paquete' element={<SolicitarPaquete />} />
        <Route path='solicitar-retiro' element={<SolicitarRetiro />} />
        <Route path='tabla-responsabilidad' element={<TablaResponsabilidad /> } />
        <Route path='adquirir-deposito-bancario' element={<DepositoBancario />} />
        <Route path='canjear-tarjeta-prepaga' element={<AdquirirSaldo />} />
        <Route path='confirmar-fondeo-global66' element={<ConfirmarFondeoGlobal />} />
        <Route path='confirmar-fondeo-usdt' element={<ConfirmarFondeoUsdt />} />
        <Route path='adelanto-de-saldo' element={<AdelantoDeSaldo />} />
        <Route path='adquirir-mantenimiento' element={<AdquirirMantenimiento />} />
        <Route path='retiro-extemporaneo' element={<RetiroExteporaneo />} />
        <Route path='comprar-puntos-ag' element={<CompraPuntosAG />} />
        <Route path='stp/admin' element={<RequireAdmin />}>
          <Route path='ordenes' element={<Ordenes />} />
        RRRRRR</Route>
        <Route path='mis-datos/editar' element={<EditProfile />} />
      </Route>
      <Route path="/old" element={<OldPortal />}>
        <Route path='pagos-y-servicios' element={<PagosServicios />} />
        <Route path='stp/generar-clabe' element={<GenerarClabe />} />
        <Route path='stp/ver-movimientos' element={<VerMovimientos />} />
        <Route path='mis-transferencias' element={<VerMiSaldo />} />
        <Route path='estadocuenta' element={<EstadoCuenta />} />
        <Route path='compras-servicios' element={<ComprasServicios />} />
        <Route path='representantes-en-red' element={<RepresentantesRed />} />
        <Route path='tus-comisiones' element={<Comisiones />} />
        <Route path='faq' element={<Faq />} />
        <Route path='news' element={<News />} />
        <Route path='my-qualifications' element={<MyQualifications />} />
        <Route path='my-contacts' element={<MyContacts />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='retiros' element={<Retiros />} />
        <Route path='saldos' element={<Saldos />} />
        <Route path='paquetes' element={<Paquetes />} />
        <Route path='transferencias' element={<Transferencias />} />
        <Route path='concursos-y-premios' element={<ConcursosPremios />} />
        <Route path='concursos-y-premios-viajes' element={<Viajes />} />
        <Route path='recomiendate' element={<Recomiendate />} />
        <Route path='cronograma-de-actividades' element={<Activities />} />
        <Route path='solicitar-paquete-fondeo' element={<PaqueteFondeo />} />
        <Route path='paquete-fondeo-adquirido' element={<PaqueteAdquirido />} />
        <Route path='solicitar-transferencia' element={<SolicitarTransferencia />} />
        <Route path='transferencia-pendiente' element={<TransferenciaPendiente />} />
        <Route path='solicitar-paquete' element={<SolicitarPaquete />} />
        <Route path='solicitar-retiro' element={<SolicitarRetiro />} />
        <Route index element={<MyContacts />} />
        <Route path='stp/admin' element={<RequireAdmin />}>
          <Route path='ordenes' element={<Ordenes />} />
        </Route>
        <Route path='panel-observacion' element={<PanelObservacion />} />
        <Route path='mis-datos/editar' element={<EditProfile />} />
      </Route>
      <Route path={ROUTES.ADMIN_PANEL} element={<AdminLayout />}>
        <Route path="home" element={<AdminHome />} />
        <Route path="logs">
          <Route path='http' element={<LogHttp />} />
        </Route>
        <Route path="metricas">
          <Route path='registro' element={<MetricsRegister />} />
        </Route>
        <Route path="agencia-de-viajes">
          <Route path="abiertas" element={<AgenciaDeViajeAbiertas />} />
          <Route path="cerradas" element={<AgenciaDeViajeCerradas />} />
          <Route path="confirmadas" element={<AgenciaDeViajeConfirmadas />} />
        </Route>
        <Route path="solicitudes">
          <Route path="fondeo" element={<SolicitudesFondeo />} />
          <Route path="retiro" element={<SolicitudesRetiro />} />
          <Route path="retiro-extemporaneo" element={<SolicitudesRetioExtemporaneo />} />
          <Route path="paquete" element={<SolicitudesPaquete />} />
          <Route path="transferencia" element={<SolicitudesTransferencias />} />
          <Route path="adelanto-de-saldo" element={<SolicitudesAdelantoSaldo />} />
        </Route>
        <Route path={ROUTES.USUARIOS}>
          <Route path="ver-representantes/:id" element={<RepresentantesEnRed />} />
          <Route path={ROUTES.USUARIOS_REGISTRADOS} element={<UsuariosRegistrados />} />
          <Route path={`${ROUTES.VER_USUARIO}/:id`} element={<Usuario />} />
          <Route path="inscriptos-concurso" element={<InscriptosConcurso />} />
          <Route path="pendientes-activacion" element={<UsuariosPendientesActivacion />} />
          <Route path={ROUTES.USUARIOS_REPRESENTANTES_ACTIVOS} element={<UsuariosRepresentantesActivos />} />
          <Route path={ROUTES.USUARIOS_REPRESENTANTES} element={<UsariosRepresentantes />} />
          <Route path={ROUTES.USUARIOS_DESHABILITADOS} element={<UsuariosDeshabilitados />} />
          <Route path={ROUTES.USUARIOS_SOLICITUDES_VALIDACION} element={<UsuariosSolicitudValidacion />} />
        </Route>
        <Route path={ROUTES.PROCESO}>
          <Route path={`${ROUTES.AGREGAR_PROCESO_USUARIO}/:id`} element={<AgregarProceso />} />
        </Route>
        <Route path={ROUTES.PAQUETES}>
          <Route path="activos" element={<PaquetesActivos />} />
          <Route path="cancelados" element={<PaquetesCancelados />} />
          <Route path={`${ROUTES.USUARIO}/:id`} element={<PaquetesUsuario />} />
          <Route path={`${ROUTES.AGREGAR_PAQUETE_USUARIO}/:id`} element={<AgregarPaqueteUsuario />} />
        </Route>
        <Route path="rendimientos">
          <Route path="pagados" element={<RendimientosPagados />} />
        </Route>
        <Route path="pago-sistema-apple">
          <Route path="listar" element={<ListarPagoAS />} />
        </Route>
        <Route path="concursos-y-premios">
          <Route path="concurso-de-viajes" element={<ConcursoViajes />} />
          <Route path="pagar-viaje/:id" element={<PagarViaje />} />
        </Route>
        <Route path="boletos-vendidos">
          <Route path="listar" element={<ListarBoletosVendidos />} />
        </Route>
        <Route path="soporte">
          <Route path="" element={<ListarSoporte />} />
        </Route>
        <Route path="puntos-gerenados">
          <Route path="apple-golden" element={<ListarPuntosAppleGolden />} />
        </Route>
        <Route path="banco-aenp">
          <Route path="fondear" element={<FondearBancoAenp />} />
          <Route path="retirar" element={<RetirarBancoAenp />} />
          <Route path="ver-balance" element={<VerBalanceBancoAenp />} />
        </Route>
        <Route path="tarjetas-prepagas">
          <Route path="disponibles" element={<TarjetasPrepagasDisponibles />} />
          <Route path="confirmadas" element={<TarjetasPrepagasConfirmadas />} />
          <Route path="no-confirmadas" element={<TarjetasPrepagasNoConfirmadas />} />
        </Route>
        <Route path="porcentaje-diario" element={<PorcentajeDiario />} />
        <Route path="ganancias-inscripciones-calificaciones" element={<GananciasCalifInsc />} />
        <Route path="graficos">
          <Route path="usuarios-registrados" element={<UserChart />} />
        </Route>
        <Route path="*" element={<AdminHome />} />
      </Route>
      <Route path="*" element={<div>
        <h1 className='text-center pt-5'>404</h1>
        <h5 className='text-center pt-1'>Page Not Found</h5>
      </div>} />
    </Routes>
  );
}
